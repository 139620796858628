<template>
  <div>
    <!-- In this page, functionality has yet to be added -->
    <ui-page-hero :title="'Videos'" />

    <transition name="fade" mode="out-in">
      <div>
        <!-- Start of 'Impression Videos' column -->
        <div class="section is-medium no-padding-top">
          <message :message="''" />

          <!-- Start of 'Add video' and 'Preview' column -->
          <div class="columns is-multiline">
            <!-- Add video column -->
            <div class="column no-padding-bottom">
              <!-- Did not yet translate the input field's labels -->
              <ui-base-input-field
                :labelForInput="false"
                v-model="newVideo.Name"
                label="Title"
              />

              <div class="wrapper-source columns is-variable is-2">
                <div class="column">
                  <label class="label">Video provider</label>
                  <div class="select">
                    <select v-model="newVideo.VideoProvider">
                      <option
                        v-for="(videoProvider, index) in videoProviders"
                        :key="index"
                        :value="videoProvider"
                        >{{ videoProvider }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="column">
                  <label class="label">Embed URL</label>
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      v-model="newVideo.VideoUrl"
                      placeholder="Embed URL"
                    />
                  </div>
                </div>
              </div>

              <button @click="addLocationVideo" class="button is-success">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>Add video</span>
              </button>
            </div>
            <!-- Preview column -->
            <div class="column is-half">
              <!-- Video preview placeholder -->
              <component
                v-if="newVideo.VideoUrl"
                v-bind:is="getComponentType(newVideo)"
                :src="newVideo.VideoUrl"
              ></component>

              <figure
                v-else
                id="video_preview_placeholder"
                class="image is-16by9 has-background-grey-light has-text-centered"
              >
                <div>
                  <span class="icon is-large">
                    <font-awesome-icon
                      class="is-size-1"
                      :icon="['fa', 'caret-right']"
                      size="2x"
                    />
                  </span>
                </div>
              </figure>
            </div>
          </div>
        </div>

        <!-- Start of 'Videos' column -->
        <div class="section is-medium">
          <!-- start of video dump section -->
          <div class="columns is-mobile is-multiline">
            <div class="column is-full">
              <div class="title is-3">Added videos</div>
              <message :message="''" />
            </div>
            <div
              v-for="(video, index) in location.Videos"
              :key="index"
              class="column is-full-mobile is-half-tablet is-one-third-desktop"
            >
              <div class="card">
                <div class="card-content">
                  <!-- <font-awesome-icon
                    class="is-hidden-mobile has-margin-bottom"
                    :icon="['fa', 'arrows-alt']"
                  /> -->
                  <!-- Video preview placeholder -->
                  <div
                    class="has-background-grey-light has-text-centered has-margin-bottom"
                  >
                    <component
                      v-bind:is="getComponentType(video)"
                      :src="video.VideoUrl"
                    ></component>
                  </div>
                  <div class="has-text-right">
                    <a
                      @click="setShowEditLocationVideo"
                      class="has-text-info has-margin-right"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fa', 'edit']" />
                      </span>
                      <span>Edit</span>
                    </a>
                    <a
                      @click="deleteLocationVideo(video, index)"
                      class="has-text-danger"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fa', 'trash-alt']" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import locationProvider from '@/providers/location'

const Message = () => import('@/components/UI/Message')
const YoutubeEmbed = () => import('@/components/UI/Components/YoutubeEmbed')
const VimeoEmbed = () => import('@/components/UI/Components/VimeoEmbed')
const UiBaseInputField = () => import('@/components/UI/Form/BaseInputField')

export default {
  components: {
    message: Message,
    'vimeo-embed': VimeoEmbed,
    'youtube-embed': YoutubeEmbed,
  },

  data() {
    return {
      isLoading: false,
      isSaving: false,
      newVideo: null,
      showEditLocationVideo: false,
      videoProviders: ['Youtube', 'Vimeo'],
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {
    this.newVideo = this.resetNewVideo()
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    getComponentType(videoSource) {
      let component = ''

      switch (videoSource.VideoProvider) {
        case 'Youtube':
          component = 'youtube-embed'
          break
        case 'Vimeo':
          component = 'vimeo-embed'
          break
      }
      return component
    },

    resetNewVideo() {
      let self = this

      return {
        Id: 0,
        LocationId: self.location.Id,
        Name: '',
        VideoProvider: 'Youtube',
        VideoUrl: '',
      }
    },

    addLocationVideo() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .createLocationVideo(self.location.Id, self.newVideo)
          .then((response) => {
            if (response.status === 200) {
              let mLocation = JSON.parse(JSON.stringify(self.location))
              mLocation.Videos.push(response.data)

              self.setLocation(mLocation)

              self.newVideo = self.resetNewVideo()
            }
          })
          .catch((e) => {})
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    deleteLocationVideo(video, index) {
      let self = this

      locationProvider.methods
        .deleteLocationVideo(video.Id)
        .then((response) => {
          if (response.status === 204) {
            let mLocation = JSON.parse(JSON.stringify(self.location))
            mLocation.Videos.splice(index, 1)

            self.setLocation(mLocation)
          }
        })
        .catch((e) => {})
        .finally(() => {
          self.isSaving = false
        })
    },

    setShowEditLocationVideo() {
      this.showEditLocationVideo = true
    },

    hideModal() {
      this.showEditLocationVideo = false
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper-source {
  .column {
    &:first-child {
      flex-basis: 130px;
      flex-grow: 0;
      flex-shrink: 0;
      .select,
      select {
        width: 100%;
      }
    }
  }
}

#video_preview_placeholder {
  position: relative;
  div {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
